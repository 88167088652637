import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { useState,useMemo } from "react"
const useGetTransactions = (page ) => {
    const [isNext, setIsNext ] = useState(false);
    const getTransactions = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/transactions/get-transactions?page=${page}`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-transactions", page], queryFn: getTransactions })
    const transactions = data?.data
    // const isNext = data?.data?.length ===7
    // console.log("transactions", transactions)
    useMemo(() => {

        if (Array.isArray(transactions) && transactions.length === 7) {
            setIsNext(true)
        } else {
            setIsNext(false)
        }
    }, [data])
    if (Array.isArray(transactions) && transactions.length === 7){

        return { transactions: transactions.slice(0, -1), isLoading, isError, error, refetch,isNext }
    }else{
        return { transactions, isLoading, isError, error, refetch,isNext }
    }
}
export default useGetTransactions