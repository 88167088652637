import React from "react"
import { Button } from "react-bootstrap"
import { useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import Breadcrumbs from "./components/Common/Breadcrumb"
import Breadcrumbs from "components/Common/Breadcrumb"
import CardModal from "./modals/CardModal"
import Spinner from 'react-bootstrap/Spinner';

import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap"
import Table from 'react-bootstrap/Table';
import useGetTransactions from "./hooks/useGetTransactions"
function formatDateToTurkeyTime(dateString) {
    const date = new Date(dateString);
    // Options for formatting in Turkey time
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        // hour12: true,
        timeZone: 'Europe/Istanbul',
    };
    return date.toLocaleString('tr-TR', options);
}


const Transactions = () => {
    const [page, setPage] = useState(0);
    const [show, setShow] = useState(false);
    const [transactionId, setTransactionId] = useState(0);
    const { transactions, isLoading, isError, error, refetch, isNext } = useGetTransactions(page)
    const handleNext = () => {
        if (isNext) {
            setPage((prev) => prev + 1)
        }
    }
    const handlePrev = () => {
        if (Array.isArray(transactions) && page !== 0) {
            setPage((prev) => prev - 1)
        }
    }
    // console.log(transactions);
    let debt = []
    let Nodebt = []
    if (Array.isArray(transactions)) {
        debt = transactions.filter((transaction) => transaction.debt !== 0)
        Nodebt = transactions.filter((transaction) => transaction.debt === 0)
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Siparişler" breadcrumbItem="Hesap Hareketleri" />
                    <Row>
                        <Card>
                            <CardBody>
                                <Col className="col-12 text-center">
                                    <Table responsive hover >
                                        <thead className="">
                                            <tr className="">
                                                <th>İD</th>
                                                <th>Tarih</th>
                                                <th>Ödeme</th>
                                                <th>Borç</th>
                                                <th>Transfer Adı</th>
                                                <th>Transfer Iban</th>
                                                <th>Sipariş Tutarı</th>
                                                <th>Kargo Ücreti</th>
                                                <th>Pazar Yeri Komisyonu</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <>
                                                    <tr>
                                                        <td className="text-center" colSpan="11"><Spinner animation="border" /></td>
                                                    </tr>
                                                </>
                                            ) : isError ? (
                                                <>
                                                    <tr>
                                                        <td className="text-center" colSpan="11">Hata</td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <>
                                                    {debt.map((transaction) => {
                                                        return (
                                                            <tr className="" key={transaction?.order_transaction_id}>
                                                                <td>
                                                                    {transaction?.order_transaction_id}
                                                                </td>
                                                                <td>
                                                                    {formatDateToTurkeyTime(transaction?.created_at)}
                                                                </td>
                                                                <td>
                                                                    {transaction?.payment}₺
                                                                </td>
                                                                <td>
                                                                    {transaction?.debt}₺
                                                                </td>
                                                                <td>
                                                                    {transaction?.transfer_name}
                                                                </td>
                                                                <td>
                                                                    {transaction?.transfer_iban}
                                                                </td>
                                                                <td>
                                                                    {transaction?.paid_amount}₺
                                                                </td>
                                                                <td>
                                                                    {transaction?.shipping_costs}
                                                                </td>
                                                                <td>
                                                                    {transaction?.marketplace_commission}
                                                                </td>
                                                                <td className="d-flex align-items-center">
                                                                    {/* <DebtForm id={transaction.order_transaction_id} /> */}
                                                                    <Button
                                                                        disabled={transaction?.status === "success" ? true : false}
                                                                        variant={transaction?.status === "success"?"outline-success":"outline-danger"}
                                                                        onClick={() => {
                                                                            setTransactionId(transaction?.order_transaction_id)
                                                                            setShow(true)
                                                                        }} >{transaction?.status !== "success" ? "Borç Öde" : "Borç Ödendi"}</Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    {Nodebt.map((transaction) => {
                                                        return (
                                                            <tr className="" key={transaction?.order_transaction_id}>
                                                                <td>
                                                                    {transaction?.order_transaction_id}
                                                                </td>
                                                                <td>
                                                                    {formatDateToTurkeyTime(transaction?.created_at)}
                                                                </td>
                                                                <td>
                                                                    {transaction?.payment}₺
                                                                </td>
                                                                <td>
                                                                    {transaction?.debt}₺
                                                                </td>
                                                                <td>
                                                                    {transaction?.transfer_name}
                                                                </td>
                                                                <td>
                                                                    {transaction?.transfer_iban}
                                                                </td>
                                                                <td>
                                                                    {transaction?.paid_amount}₺
                                                                </td>                                                        
                                                                <td>
                                                                    {transaction?.shipping_costs}
                                                                </td>
                                                                <td>
                                                                    {transaction?.marketplace_commission}
                                                                </td>
                                                                <td className="">
                                                                    {" "}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col className=" text-end mt-3" xs="12">
                                    <Button disabled={page === 0} onClick={handlePrev} style={{ borderRadius: "8px 0px 0px 8px" }} variant="light" >Önceki</Button>
                                    <Button disabled={!isNext} onClick={handleNext} style={{ borderRadius: "0px 8px 8px 0px" }} variant="light" >Sonraki</Button>
                                </Col>
                            </CardBody>
                        </Card>
                    </Row>
                    <CardModal transactionId={transactionId} show={show} setShow={setShow} />
                </div>
            </div>
        </>
    )
}
export default Transactions