import React, { useState, useEffect } from "react"
import axios from "axios"
import * as url from "../../../../helpers/url_helper"
import ErrorDisplay from "../hataGöster/ErrorDisplay"
import { Col, Row } from "reactstrap"

// ! Adres kısmında şehir, ilçe, mahalle, ve cadde/sokak bilgilerini almak için yapılan bileşen.
// * Çok fazla prop olduğu için burası da Zustand için address slice'ına katılabilir.
// * Ya da iç ve dış kapı numaralarıyla bu bileşen birleştirilebilir.
function Selector({
  city,
  setCity,
  district,
  setDistrict,
  neighborhood,
  setNeighborhood,
  street,
  setStreet,
  cityError,
  districtError,
  neighborhoodError,
  streetError,
  apartmentNo,
  setApartmentNo,
  houseNo,
  setHouseNo,
  apartmentNoError,
  houseNoError,
  address,
  setAddress,
  addressError,
}) {
  const [iller, setIller] = useState([])
  const [ilceler, setIlceler] = useState([])
  const [mahalleler, setMahalleler] = useState([])

  const [cityId, setCityId] = useState(0)
  const [districtId, setDistrictId] = useState(0)

  // * İl verilerini çekme
  useEffect(() => {
    axios
      .get(url.GET_AUTH_CITY, {
        withCredentials: true,
      })
      .then(response => {
        const data = response.data.sort((a, b) => a.id - b.id)
        setIller(data)
      })
      .catch(error => {
        console.error("İl verilerini alma sırasında bir hata oluştu: ", error)
      })
  }, [])

  // * İlçe verilerini çekme (city_id değişkenine bağlı)
  useEffect(() => {
    if (cityId !== 0) {
      axios
        .get(`${url.GET_AUTH_DISTRICT}/${cityId}`, {
          withCredentials: true,
        })
        .then(response => {
          const data = response.data.sort((a, b) => a.id - b.id)
          setIlceler(data)
        })
        .catch(error => {
          console.error(
            "İlçe verilerini alma sırasında bir hata oluştu: ",
            error.response.data
          )
        })
    }
  }, [cityId])

  // * Mahalle verilerini çekme (district_id değişkenine bağlı)
  useEffect(() => {
    if (districtId !== 0) {
      axios
        .get(`${url.GET_AUTH_NEIGBORHOOD}/${districtId}`, {
          withCredentials: true,
        })
        .then(response => {
          const data = response.data.sort((a, b) => a.id - b.id)
          console.log(data)
          setMahalleler(data)
        })
        .catch(error => {
          console.error(
            "Mahalle verilerini alma sırasında bir hata oluştu: ",
            error
          )
        })
    }
  }, [districtId])

  const handleCityChange = e => {
    const selectedCity = JSON.parse(e.target.value)
    setCityId(selectedCity.id)
    setCity(selectedCity.name)
    setDistrictId(0)
    setDistrict("")
    setNeighborhood("")
    setStreet("")
  }

  const handleDistrictChange = e => {
    const selectedDistrict = JSON.parse(e.target.value)
    setDistrictId(selectedDistrict.id)
    setDistrict(selectedDistrict.name)
    setNeighborhood("")
    setStreet("")
  }

  const handleNeighborhoodChange = e => {
    const yeniMahalle = e.target.value
    setNeighborhood(yeniMahalle)
    setStreet("")
  }

  const handleStreetChange = e => {
    const yeniSokak = e.target.value

    const lettersOnly = yeniSokak.replace(/[^a-zA-ZçÇşŞıİğĞüÜöÖ\s]/g, "")
    setStreet(lettersOnly)
  }

  const handleApartmentNoChange = event => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, "")
    setApartmentNo(sanitizedValue)
  }

  const handleHouseNoChange = event => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, "")
    setHouseNo(sanitizedValue)
  }

  return (
    <div>
      <div>
        <label>İl</label>
        <select
          value={JSON.stringify({ id: cityId, name: city })}
          onChange={handleCityChange}
          className="form-select"
          aria-label="İl seçin"
          style={{ padding: "20px" }}
        >
          <option value="">İl Seçin</option>
          {iller.map(il => (
            <option
              key={il.il_id}
              value={JSON.stringify({ id: il.il_id, name: il.il })}
            >
              {il.il}
            </option>
          ))}
        </select>
        <ErrorDisplay error={cityError} />
      </div>

      <div style={{ marginTop: "15px" }}>
        <label>İlçe</label>
        <select
          value={JSON.stringify({ id: districtId, name: district })}
          onChange={handleDistrictChange}
          className="form-select"
          aria-label="İlçe seçin"
          style={{ padding: "20px" }}
          disabled={cityId === 0}
        >
          <option value="">İlçe Seçin</option>
          {ilceler.map(ilce => (
            <option
              key={ilce.ilce_id}
              value={JSON.stringify({ id: ilce.ilce_id, name: ilce.ilce })}
            >
              {ilce.ilce}
            </option>
          ))}
        </select>
        <ErrorDisplay error={districtError} />
      </div>

      <div style={{ marginTop: "15px" }}>
        <label>Mahalle</label>
        <select
          value={neighborhood}
          onChange={handleNeighborhoodChange}
          className="form-select"
          aria-label="Mahalle seçin"
          style={{ padding: "20px" }}
          disabled={districtId === 0}
        >
          <option value="">Mahalle Seçin</option>
          {mahalleler.map(mahalle => (
            <option key={mahalle.mahalle_id} value={mahalle.mahalle}>
              {mahalle.mahalle}
            </option>
          ))}
        </select>
        <ErrorDisplay error={neighborhoodError} />
      </div>

      <div style={{ marginTop: "15px", maxHeight: "200px", overflowY: "auto" }}>
        <label>Sokak</label>
        <div>
          <input
            type="email"
            value={street}
            onChange={handleStreetChange}
            className="form-control"
            placeholder="Sokak giriniz"
            style={{ padding: "20px" }}
          />
        </div>
        <ErrorDisplay error={streetError} />
      </div>
      {/* İç ve dış kapı kutucukları */}
      {/* Burada bugün oluşturduğum FormInput bileşeni kullanılabilir */}
      <Row style={{ marginTop: "20px" }}>
        <Col lg="6">
          <span className="inputTitle">Dış Kapı No</span>
          <div>
            <input
              type="text"
              value={houseNo}
              onChange={handleHouseNoChange}
              className="form-control"
              placeholder="Dış kapı no giriniz"
              style={{ padding: "20px", marginTop: "5px" }}
            />
          </div>
          <ErrorDisplay error={apartmentNoError} />
        </Col>

        <Col lg="6">
          <span className="inputTitle">İç Kapı No</span>
          <div>
            <input
              type="text"
              value={apartmentNo}
              onChange={handleApartmentNoChange}
              className="form-control"
              placeholder="Dış kapı no giriniz"
              style={{ padding: "20px", marginTop: "5px" }}
            />
          </div>

          <ErrorDisplay error={houseNoError} />
        </Col>
      </Row>
      {/* Adres tarifi kutucuğu */}
      <Row style={{ marginTop: "20px" }}>
        <Col lg="12">
          <span className="inputTitle">Adres Tarifi</span>
          <div className="form-floating">
            <textarea
              value={address}
              onChange={event => setAddress(event.target.value)}
              className="form-control form-text"
              placeholder="Adres girin"
              id="floatingTextarea2"
              style={{ height: "100px" }}
            ></textarea>
          </div>
          <ErrorDisplay error={addressError} />
        </Col>
      </Row>
    </div>
  )
}

export default Selector
