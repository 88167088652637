import React from "react"
import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    Collapse,
    ModalFooter,
} from "reactstrap"
import { useState } from "react"
import Kvkk from "pages/sozlesmeler/kvkk"
import Kazanc from "pages/sozlesmeler/kazanc"
import AcikRizaMetni from "pages/sozlesmeler/acikRizaMetni"
import { FcMoneyTransfer } from "react-icons/fc"
import KullanimSozlesmesi from "pages/sozlesmeler/kullanimSozlesmesi"
import { Link } from "react-router-dom"
const Settings = () => {
    const [modalOpen, setModalOpen] = useState(false)

    const toggleModal = () => {
        setModalOpen(!modalOpen)
    }
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [isOpen3, setIsOpen3] = useState(false)
    const [isOpen4, setIsOpen4] = useState(false)
    const [isOpen5, setIsOpen5] = useState(false)
    const [isOpen6, setIsOpen6] = useState(false)
    const [isOpen7, setIsOpen7] = useState(false)

    const [modalKullanimShow, setModalKullanimShow] = useState(false);
    const [modalKvkkShow, setModalKvkkShow] = useState(false)
    const [modalAcikRizaShow, setModalAcikRizaShow] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const toggle2 = () => {
        setIsOpen2(!isOpen2)
    }
    const toggle3 = () => {
        setIsOpen3(!isOpen3)
    }
    const toggle4 = () => {
        setIsOpen4(!isOpen4)
    }
    const toggle5 = () => {
        setIsOpen5(!isOpen5)
    }
    const toggle6 = () => {
        setIsOpen6(!isOpen6)
    }
    const toggle7 = () => {
        setIsOpen7(!isOpen7)
    }
    const fontSize18 = { fontSize: "14px" }
    const cardStyle = {
        width: "18rem",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    }

    const iconContainerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
    }

    const iconStyle = {
        fontSize: "48px",
    }

    const cardBodyStyle = {
        padding: "20px",
    }

    const cardTitleStyle = {
        fontSize: "1.25rem",
        marginBottom: "10px",
    }

    const cardTextStyle = {
        marginBottom: "15px",
    }
    return (
        <Card className="p-3">
            <CardBody>
                {/* <Link to="/questions">
                    <Row className="d-flex align-items-center py-1">
                        <Col className="" xs="auto">
                            <i className="bx bx-chat font-size-20"></i>
                        </Col>
                        <Col>
                            <span style={fontSize18}>Yardım (destekle iletişime geçin)</span>
                        </Col>
                    </Row>
                </Link> */}
                <Link to="/sorular">
                    <Row className="py-1">
                        <Col xs="auto">
                            <i className="bx bx-question-mark font-size-20"></i>
                        </Col>
                        <Col>
                            <span
                                onClick={toggleModal}
                                style={{ ...fontSize18, cursor: "pointer" }}
                            >
                                SSS
                            </span>
                            {/* <Modal
                            style={{
                                maxWidth: "1000px",
                                maxHeight: "1000px",
                                marginTop: "80px",
                            }}
                            isOpen={modalOpen}
                            toggle={toggleModal}
                        >
                            <ModalHeader toggle={toggleModal}>
                                Return denial form{" "}
                            </ModalHeader>
                            <ModalBody className="mx-3 my-3">
                                <Row>
                                    <Col>
                                        <div className="card" style={cardStyle}>
                                            <div
                                                className="icon-container"
                                                style={iconContainerStyle}
                                            >
                                                <FcMoneyTransfer style={iconStyle} />
                                            </div>
                                            <div className="card-body" style={cardBodyStyle}>
                                                <h5 className="card-title" style={cardTitleStyle}>
                                                    Kazancım
                                                </h5>
                                                <p className="card-text" style={cardTextStyle}>
                                                    Kazancınız ile ilğili merak ettiğiniz sorular
                                                </p>
                                                <Kazanc />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={toggleModal}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Modal> */}
                        </Col>
                    </Row>
                </Link>
                <Row className="py-1">
                    <Col>
                        <hr style={{ borderTop: "3px solid #EFF2F7" }} />
                    </Col>
                </Row>
                <Row className=" d-flex align-items-center   py-1">
                    <Col xs="auto">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M12.7284 3.41839L12.8296 3.41959V3.3184V1.69039C12.8296 0.16845 14.8042 -0.485167 15.7374 0.738748L18.9125 4.90303C19.5713 5.76701 19.5713 6.95458 18.9125 7.81856L15.7374 11.9828C14.8042 13.2068 12.8296 12.5532 12.8296 11.0312V8.94446V8.84782L12.733 8.84452C9.12181 8.72134 6.82311 10.6097 5.64228 11.9594L5.71754 12.0252L5.64228 11.9594C5.2618 12.3943 4.69009 12.4625 4.26006 12.3063C3.80985 12.1426 3.3917 11.6738 3.51444 11.0325C3.7813 9.63813 4.41694 7.60694 5.95667 5.98484C7.39416 4.47045 9.55539 3.38084 12.7284 3.41839ZM5.40321 9.81304L5.27807 10.1704L5.56334 9.92143C7.07927 8.59847 9.25433 7.35612 12.7296 7.35612H13.5874C14.0074 7.35612 14.345 7.6906 14.345 8.09973L14.3448 11.0312L14.3448 11.3273L14.5244 11.0919L17.6994 6.92757C17.6994 6.92756 17.6994 6.92756 17.6994 6.92756C17.9555 6.59182 17.9555 6.12977 17.6994 5.79403C17.6994 5.79403 17.6994 5.79402 17.6994 5.79402L14.5244 1.62975L14.3448 1.3943V1.69039V4.16198C14.3448 4.57108 14.0072 4.90553 13.5872 4.90553H12.7296C10.7815 4.90553 9.36677 5.4262 8.43757 5.94932C7.97323 6.21073 7.63055 6.47251 7.40331 6.66969C7.28969 6.76829 7.20489 6.85076 7.14815 6.90901C7.11978 6.93814 7.09842 6.96121 7.08396 6.97722L7.06745 6.99581L7.06342 7.00047C6.25429 7.85358 5.73511 8.86525 5.40321 9.81304Z"
                                fill="#6A7187"
                            />
                            <path
                                d="M2.54432 0.95144H5.08864C5.5018 0.95144 5.83674 1.28638 5.83674 1.69955C5.83674 2.11271 5.5018 2.44765 5.08864 2.44765H2.54432C2.0207 2.44765 1.59621 2.87213 1.59621 3.39576V16.9654C1.59621 17.4891 2.0207 17.9136 2.54432 17.9136H16.114C16.6377 17.9136 17.0621 17.4891 17.0621 16.9654V14.4211C17.0621 14.0079 17.397 13.673 17.8102 13.673C18.2234 13.673 18.5583 14.0079 18.5583 14.4211V16.9654C18.5583 18.3154 17.464 19.4098 16.114 19.4098H2.54432C1.19436 19.4098 0.1 18.3154 0.1 16.9654V3.39576C0.1 2.04579 1.19436 0.95144 2.54432 0.95144Z"
                                fill="#6A7187"
                            />
                        </svg>
                    </Col>
                    <Col>
                        <KullanimSozlesmesi
                            modalShow={modalKullanimShow}
                            setModalShow={setModalKullanimShow}
                        />
                        <span
                            style={{ ...fontSize18, textDecorationLine: "underline", cursor: "pointer" }}
                            onClick={() => setModalKullanimShow(true)}
                        >
                            KULLANIM KOŞULLARI ve ÜYELİK SÖZLEŞMESİ
                        </span>
                    </Col>
                </Row>
                <Row className=" d-flex align-items-center   py-1">
                    <Col xs="auto">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M12.7284 3.41839L12.8296 3.41959V3.3184V1.69039C12.8296 0.16845 14.8042 -0.485167 15.7374 0.738748L18.9125 4.90303C19.5713 5.76701 19.5713 6.95458 18.9125 7.81856L15.7374 11.9828C14.8042 13.2068 12.8296 12.5532 12.8296 11.0312V8.94446V8.84782L12.733 8.84452C9.12181 8.72134 6.82311 10.6097 5.64228 11.9594L5.71754 12.0252L5.64228 11.9594C5.2618 12.3943 4.69009 12.4625 4.26006 12.3063C3.80985 12.1426 3.3917 11.6738 3.51444 11.0325C3.7813 9.63813 4.41694 7.60694 5.95667 5.98484C7.39416 4.47045 9.55539 3.38084 12.7284 3.41839ZM5.40321 9.81304L5.27807 10.1704L5.56334 9.92143C7.07927 8.59847 9.25433 7.35612 12.7296 7.35612H13.5874C14.0074 7.35612 14.345 7.6906 14.345 8.09973L14.3448 11.0312L14.3448 11.3273L14.5244 11.0919L17.6994 6.92757C17.6994 6.92756 17.6994 6.92756 17.6994 6.92756C17.9555 6.59182 17.9555 6.12977 17.6994 5.79403C17.6994 5.79403 17.6994 5.79402 17.6994 5.79402L14.5244 1.62975L14.3448 1.3943V1.69039V4.16198C14.3448 4.57108 14.0072 4.90553 13.5872 4.90553H12.7296C10.7815 4.90553 9.36677 5.4262 8.43757 5.94932C7.97323 6.21073 7.63055 6.47251 7.40331 6.66969C7.28969 6.76829 7.20489 6.85076 7.14815 6.90901C7.11978 6.93814 7.09842 6.96121 7.08396 6.97722L7.06745 6.99581L7.06342 7.00047C6.25429 7.85358 5.73511 8.86525 5.40321 9.81304Z"
                                fill="#6A7187"
                            />
                            <path
                                d="M2.54432 0.95144H5.08864C5.5018 0.95144 5.83674 1.28638 5.83674 1.69955C5.83674 2.11271 5.5018 2.44765 5.08864 2.44765H2.54432C2.0207 2.44765 1.59621 2.87213 1.59621 3.39576V16.9654C1.59621 17.4891 2.0207 17.9136 2.54432 17.9136H16.114C16.6377 17.9136 17.0621 17.4891 17.0621 16.9654V14.4211C17.0621 14.0079 17.397 13.673 17.8102 13.673C18.2234 13.673 18.5583 14.0079 18.5583 14.4211V16.9654C18.5583 18.3154 17.464 19.4098 16.114 19.4098H2.54432C1.19436 19.4098 0.1 18.3154 0.1 16.9654V3.39576C0.1 2.04579 1.19436 0.95144 2.54432 0.95144Z"
                                fill="#6A7187"
                            />
                        </svg>
                    </Col>
                    <Col>
                        <Kvkk
                            modalShow={modalKvkkShow}
                            setModalShow={setModalKvkkShow}
                        />
                        <span
                            style={{ ...fontSize18, textDecorationLine: "underline", cursor: "pointer" }}
                            onClick={() => setModalKvkkShow(true)}
                        >
                            KVKK AYDINLATMA METNİ
                        </span>
                    </Col>
                </Row>
                <Row className=" d-flex align-items-center   py-1">
                    <Col xs="auto">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M12.7284 3.41839L12.8296 3.41959V3.3184V1.69039C12.8296 0.16845 14.8042 -0.485167 15.7374 0.738748L18.9125 4.90303C19.5713 5.76701 19.5713 6.95458 18.9125 7.81856L15.7374 11.9828C14.8042 13.2068 12.8296 12.5532 12.8296 11.0312V8.94446V8.84782L12.733 8.84452C9.12181 8.72134 6.82311 10.6097 5.64228 11.9594L5.71754 12.0252L5.64228 11.9594C5.2618 12.3943 4.69009 12.4625 4.26006 12.3063C3.80985 12.1426 3.3917 11.6738 3.51444 11.0325C3.7813 9.63813 4.41694 7.60694 5.95667 5.98484C7.39416 4.47045 9.55539 3.38084 12.7284 3.41839ZM5.40321 9.81304L5.27807 10.1704L5.56334 9.92143C7.07927 8.59847 9.25433 7.35612 12.7296 7.35612H13.5874C14.0074 7.35612 14.345 7.6906 14.345 8.09973L14.3448 11.0312L14.3448 11.3273L14.5244 11.0919L17.6994 6.92757C17.6994 6.92756 17.6994 6.92756 17.6994 6.92756C17.9555 6.59182 17.9555 6.12977 17.6994 5.79403C17.6994 5.79403 17.6994 5.79402 17.6994 5.79402L14.5244 1.62975L14.3448 1.3943V1.69039V4.16198C14.3448 4.57108 14.0072 4.90553 13.5872 4.90553H12.7296C10.7815 4.90553 9.36677 5.4262 8.43757 5.94932C7.97323 6.21073 7.63055 6.47251 7.40331 6.66969C7.28969 6.76829 7.20489 6.85076 7.14815 6.90901C7.11978 6.93814 7.09842 6.96121 7.08396 6.97722L7.06745 6.99581L7.06342 7.00047C6.25429 7.85358 5.73511 8.86525 5.40321 9.81304Z"
                                fill="#6A7187"
                            />
                            <path
                                d="M2.54432 0.95144H5.08864C5.5018 0.95144 5.83674 1.28638 5.83674 1.69955C5.83674 2.11271 5.5018 2.44765 5.08864 2.44765H2.54432C2.0207 2.44765 1.59621 2.87213 1.59621 3.39576V16.9654C1.59621 17.4891 2.0207 17.9136 2.54432 17.9136H16.114C16.6377 17.9136 17.0621 17.4891 17.0621 16.9654V14.4211C17.0621 14.0079 17.397 13.673 17.8102 13.673C18.2234 13.673 18.5583 14.0079 18.5583 14.4211V16.9654C18.5583 18.3154 17.464 19.4098 16.114 19.4098H2.54432C1.19436 19.4098 0.1 18.3154 0.1 16.9654V3.39576C0.1 2.04579 1.19436 0.95144 2.54432 0.95144Z"
                                fill="#6A7187"
                            />
                        </svg>
                    </Col>
                    <Col>
                        <span
                            style={{ ...fontSize18, textDecorationLine: "underline", cursor: "pointer" }}
                            onClick={() => setModalAcikRizaShow(true)}
                        >
                            AÇIK RIZA METNİ
                        </span>
                        <AcikRizaMetni
                            modalShow={modalAcikRizaShow}
                            setModalShow={setModalAcikRizaShow}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default Settings
